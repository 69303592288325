<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols-lg="6">
                    <b-form-group
                        class="row"
                        :label="$t('forms.station')"
                        label-for="h-station"
                        label-cols-md="4"
                        content-cols-lg="8"
                        v-if="showStation()"
                    >
                        <v-select
                            id="h-station"
                            v-model="customer.stations"
                            :options="stations"
                            taggable
                            multiple
                            push-tags
                        />
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.responseTime')"
                        label-for="responseTime"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="customer.customerData.responseTime"
                            id="responseTime"
                            type="number"
                        />
                    </b-form-group>

                    <b-form-group
                        class="row"
                        label="Type of serviced A/C / Engine type"
                        label-for="typeOfAC"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.typeOfAC" id="typeOfAC"/>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.maintenance')"
                        label-for="maintenance"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.maintenance" id="maintenance"/>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.assistance')"
                        label-for="assistance"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.assistance" id="assistance"/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Ops contacts"
                        label-for="opsContact"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.opsContact" id="opsContact"/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Daily check and other line maintenance tasks"
                        label-for="dailyCheck"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.dailyCheck" id="dailyCheck"/>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.partsStorage')"
                        label-for="partsStorage"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea
                            v-model="customer.customerData.partsStorage"
                            id="partsStorage"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols-lg="6">

                    <b-form-group
                        class="row"
                        :label="$t('forms.nitrogen')"
                        label-for="nitrogen"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.nitrogen" id="nitrogen"/>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.registrationAC')"
                        label-for="registrationAC"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea
                            v-model="customer.customerData.registrationAC"
                            id="registrationAC"
                        />
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.airworthinessData')"
                        label-for="airworthinessData"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea
                            v-model="customer.customerData.airworthinessData"
                            id="airworthinessData"
                        />
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.training')"
                        label-for="training"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.training" id="training"/>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.general')"
                        label-for="general"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.general" id="general"/>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.certificateRelease')"
                        label-for="certificateRelease"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.certificateRelease" id="certificateRelease"/>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.trainingRecords')"
                        label-for="trainingRecords"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.trainingRecords" id="trainingRecords"/>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";
import {getUserData} from "@/auth/utils";

export default {
    components: {
        vSelect,
    },
    props: {
        customerObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getResourceList(this.$Stations, this.stations);
        if ("stations" in this.customer && this.customer.stations) {
            this.customer.stations = this.selected(this.customer.stations)
        } else {
            this.customer.stations = []
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {};
    },
    data() {
        return {
            customer: this.customerObject,
            stations: [],
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                const body = JSON.parse(JSON.stringify(this.customer));
                delete body.branches
                body.stations = this.customer.stations.map(function (station) {
                    return station.id
                });
                if(this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER)) {
                    body.stations = [getUserData()['station']['@id']] 
                }
                if (this.action === "update_ops") {
                    this.$emit("clearAction");
                    this.update(
                        this.$Users,
                        this.customer.id,
                        body,
                        this.$t("messages.customerUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        showStation() {
            return !this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER)
        },
        selected(values) {
            const list = [];
            values.forEach(element => {
                list.push({
                    label: `${element.name}`,
                    id: element['@id'],
                })
            })
            return list;
        },
        success() {
            this.$router.push(`/customers`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

